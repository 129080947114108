import { validationErrorNames } from 'components/strombestilling/helpers/useValidate';
import { useAuth } from 'context/AuthProvider';
import debounce from 'lodash/debounce';
import { useState } from 'react';

export default function useBackend() {
	// More info on api here: https://strombestilling-api.nte.dev/q/swagger-ui/#/
	const { user: authUser } = useAuth();

	const [isLoading, _setIsLoading] = useState(false);
	const debouncedLoading = debounce(val => _setIsLoading(val), 500);
	const setIsLoading = val => {
		if (val) {
			_setIsLoading(val);
		} else {
			debouncedLoading(val);
		}
	};
	const idToken = authUser?.access_token;
	const backendUrl = process.env.GATSBY_STROM_SERVER_URL;

	async function getData(url, useNoAuth) {
		setIsLoading(true);
		try {
			return await fetch(backendUrl + url, {
				method: 'GET',
				headers: {
					Authorization:
						idToken && !useNoAuth ? `Bearer ${idToken}` : '',
				},
			}).then(handleResponse);
		} catch (error) {
			setIsLoading(false);
			return { error: error };
		}
	}

	async function setData(url, body, method, useNoAuth) {
		setIsLoading(true);
		try {
			return await fetch(backendUrl + url, {
				method: method || 'POST',
				body: JSON.stringify(body),
				headers: {
					Authorization:
						idToken && !useNoAuth ? `Bearer ${idToken}` : '',
					'Content-Type': 'application/json',
				},
			}).then(handleResponse);
		} catch (error) {
			setIsLoading(false);
			return { error: error };
		}
	}

	async function handleResponse(response) {
		// Based on this: https://mcculloughwebservices.com/2016/09/23/handling-a-null-response-from-an-api/
		setIsLoading(false);
		const text = await response.text();
		if (text.length) {
			if (!response.ok) {
				return { error: JSON.parse(text) };
			} else {
				return JSON.parse(text);
			}
		} else if (200 <= response.status && response.status <= 203) {
			return response.status;
		} else {
			return { error: response.status };
		}
	}

	async function getUserInfo(ssn) {
		return await getData('/info/person-oppsummering?ssn=' + ssn);
	}

	async function getBedrift(orgnr) {
		return await getData('/info/bedrift-oppsummering?orgnr=' + orgnr);
	}

	async function getMeterInfoFromAddress(gate, husnummer, postnummer) {
		return await getData(
			'/maalepunkter?gate=' +
				gate +
				'&nummer=' +
				husnummer +
				'&postnummer=' +
				postnummer
		);
	}

	async function getMeterInfoFromMeternumber(maalernummer, postnummer) {
		return await getData(
			'/maalepunkter?maalernummer=' +
				maalernummer +
				'&postnummer=' +
				postnummer
		);
	}

	async function getMeterInfoFromMeterpoindId(maalepunktId) {
		return await getData('/maalepunkter?maalepunktId=' + maalepunktId);
	}

	async function createNewOrder(body) {
		return await setData('/ordre', body);
	}

	async function getTariff(id) {
		return await getData('/tariffer/' + id, true);
	}

	async function getSale(salgskode) {
		return await getData(`/power/${salgskode}`, null, 'post', true);
	}

	async function updateSaleStatus(salgskode, nyStatus) {
		const body = {
			status: nyStatus,
		};

		return await setData(`/power/${salgskode}/status`, body, 'PUT', true);
	}

	async function getVervingerMinimal(kode) {
		return await getData('/vervinger/' + kode + '/minimal', true);
	}

	async function validateTariffregler(
		tariffId,
		maalepunktIder,
		vervekode,
		salgskode
	) {
		const errorNames = Object.keys(validationErrorNames);
		const fetchingResult = await setData(
			'/tariffregler/valider',
			{
				tariffId,
				maalepunktIder,
				vervekode,
				salgskode,
			},
			'POST',
			true
		);
		if (!fetchingResult?.error) {
			return fetchingResult;
		} else {
			return (
				{
					errorList: fetchingResult?.error?.errors?.sort(
						(a, b) =>
							errorNames.indexOf(a.validationName) -
							errorNames.indexOf(b.validationName)
					),
				} || { error: fetchingResult?.error }
			);
		}
	}

	async function postFeedback(orderId, message, canContact) {
		const body = {
			orderId: orderId,
			message: message,
			canContact: canContact || false,
		};
		return await setData('/feedback', body);
	}

	return {
		isLoading,
		getUserInfo,
		getBedrift,
		getMeterInfoFromAddress,
		getMeterInfoFromMeternumber,
		getMeterInfoFromMeterpoindId,
		createNewOrder,
		getTariff,
		updateSaleStatus,
		getSale,
		getVervingerMinimal,
		validateTariffregler,
		postFeedback,
	};
}
