import { faHouseChimneyHeart } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyHeart';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faAt } from '@fortawesome/pro-solid-svg-icons/faAt';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { AnchorLink } from 'components/Link';
import useSaveConsent from 'components/consent-portal/useSaveConsent';
import InfoBox from 'components/strombestilling/components/InfoBox';
import InlineModal, {
	EditFormVariants,
	InfoBoxVariants,
} from 'components/strombestilling/components/InlineModal';
import InputField from 'components/strombestilling/components/InputField';
import PhoneInputField from 'components/strombestilling/components/PhoneInputField';
import { ProductBoxMobileOnly } from 'components/strombestilling/components/ProductBox';
import SearchAddressField, {
	addressObjectToString,
} from 'components/strombestilling/components/SearchAddressField';
import {
	StromFormError,
	StromFormRequired,
} from 'components/strombestilling/components/StromFormMsgs';
import {
	Button,
	ContentWrapper,
	Form,
	GreenButton,
	Header,
	Layout,
	Push,
	SubHeader,
} from 'components/strombestilling/components/StromStyles';
import StyledCheckbox from 'components/strombestilling/components/StyledCheckbox';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import usePageNavigation, {
	stromPages,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useTracking from 'components/strombestilling/helpers/useTracking';
import {
	ANGRERETT_FRIST_DAGER,
	isDateBeforeAngrerett,
} from 'components/strombestilling/pages/addStartdate';
import { trackGoogleAdsConversion } from 'context/AnalyticsProvider';
import { useAuth } from 'context/AuthProvider';

function formatPhoneToString(number) {
	if (!number) {
		return '';
	}
	try {
		var cleaned = ('' + number).replace(/\D/g, '');
		if (cleaned?.length === 8) {
			return `${cleaned.slice(0, 3)} ${cleaned.slice(
				3,
				5
			)} ${cleaned.slice(5)}`;
		} else if (cleaned?.length === 10) {
			return `${cleaned.slice(0, 2)} ${cleaned.slice(
				2,
				5
			)} ${cleaned.slice(5, 7)} ${cleaned.slice(7)}`;
		} else {
			return cleaned;
		}
	} catch {
		return number;
	}
}

export default function ConfirmOrder() {
	const {
		order,
		updateOrder,
		user,
		updateUser,
		storeBeforeLogout,
		constructOrder,
		storedElektroOrder,
	} = useContext(StromContext);
	const { isLoading: isBackendLoading, createNewOrder } = useBackend();
	const { isLoading: isAuthLoading, logout } = useAuth();
	const { getLogoutUrl } = usePageNavigation();
	const { trackButtonClicked } = useTracking();
	const { updateSaleStatus } = useSale();
	const { navigatePage } = usePageNavigation();
	const { saveConsent } = useSaveConsent();

	const [editContactInfoOpen, setEditContactInfoOpen] = useState(false);
	const [pickedAddress, setPickedAddress] = useState(
		order?.fakturaAdresse || null
	);
	const [addressObject, setAddressObject] = useState();
	const addressInputRef = useRef(null);
	const [formError, setFormError] = useState('');
	const defaultEditFormError = {
		tel: '',
		address: '',
		epost: '',
	};
	const [editFormError, setEditFormError] = useState(defaultEditFormError);
	const [searchIsActive, setSearchIsActive] = useState(false);

	const [isBedrift, setIsBedrift] = useState(false);
	const [hasAngrerett, setHasAngrerett] = useState(false);

	useEffect(() => {
		if (order.isBedrift) {
			setIsBedrift(true);
		} else {
			setIsBedrift(false);
		}
	}, [order.isBedrift]);

	useEffect(() => {
		if (
			typeof order.tariffData?.angrerett === 'boolean' &&
			order.tariffData?.angrerett
		) {
			setHasAngrerett(true);
		}
	}, [order.tariffData?.angrerett]);

	useEffect(() => {
		updateSaleStatus(salgsStatuser.lastStep);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (order?.fakturaAdresse) {
			setPickedAddress(order.fakturaAdresse);
		}
	}, [order]);

	function validateContactInfoChange(input) {
		const adresse = pickedAddress || addressObject;
		if (!input) {
			return false;
		}

		if (!input.cc) {
			setEditFormError({
				...editFormError,
				tel: 'Landskode mangler. 47 er landskode for Norge',
			});
			return false;
		}
		if (input.cc.length > 3) {
			setEditFormError({
				...editFormError,
				tel: 'Oppgitt landskode er feil. 47 er landskode for Norge',
			});
			return false;
		}
		if (input.telefonnummer?.length < 8) {
			setEditFormError({
				...editFormError,
				tel: 'For kort telefonnummer',
			});
			return false;
		}
		if (!input.epost) {
			setEditFormError({
				...editFormError,
				epost: 'E-postadresse mangler',
			});
			return false;
		}
		if (!adresse) {
			setEditFormError({
				...editFormError,
				address: 'Fakturaadresse må fylles ut',
			});
			return false;
		}
		return true;
	}

	function updateContactInfo(input) {
		const adresse = pickedAddress || addressObject;

		updateOrder({
			fakturaAdresse: adresse || order.fakturaAdresse,
		});

		updateUser({
			epost: input.epost || user.epost,
			mobil: input.telefonnummer || user.mobil,
			landskode: input.cc || user.landskode,
		});
	}

	/**
	 * Function to submit the order
	 * @returns {void}
	 */
	function onSubmit(e) {
		e?.preventDefault();
		setFormError('');

		if (editContactInfoOpen) {
			setFormError(
				'Boksen for å endre kontakinformasjon er åpen. Lukk den, eller lagre adressen for å fortsette.'
			);
			return;
		}

		// Create new order
		createNewOrder(constructOrder())
			.then(async data => {
				// Store order before logout
				storeBeforeLogout(data?.id || null);

				// If error, logout with error message
				if (data.error) {
					// If order is duplicate, logout with duplicate error message
					if (
						data.error?.errors?.[0]?.validationName ===
						'IkkeDuplikatOrdre'
					) {
						logout({
							redirect: getLogoutUrl(
								stromPages.orderFailed.url,
								stromUrlParams.duplicateOrderError
							),
						});
						// If order is not duplicate, logout with technical error message
					} else {
						logout({
							redirect: getLogoutUrl(
								stromPages.orderFailed.url,
								stromUrlParams.techError
							),
						});
					}
					// If credit check is not ok, logout with credit check error message
				} else if (!data?.kredittsjekk?.kredittsjekkOk) {
					logout({
						redirect: getLogoutUrl(
							stromPages.orderFailed.url,
							data?.kredittsjekk?.kredittsperreFinnes
								? stromUrlParams.creditBlockedError
								: stromUrlParams.creditFailedError
						),
					});
					// If no error, logout with success
				} else {
					// If user has accepted marketing cookies, run saveConsent
					if (order?.samtykkeMarkedsfoering) {
						await saveConsent({
							event: 'Strøm',
							email: user.epost || '',
							phone: user.mobil || '',
							action: 'Form Submitted',
						});
					}

					// If order is manual (can't be automatically processed) - considered request instead of successfull order
					if (order.isManual) {
						// If user has stored elektro product order in session storage, give them custom error message (almost impossible to trigger)
						if (!!storedElektroOrder) {
							navigatePage(
								getLogoutUrl(
									stromPages.orderFailed.url,
									stromUrlParams.manualElektroError
								)
							);
						} else {
							logout({
								redirect: getLogoutUrl(
									stromPages.orderSuccess.url,
									stromUrlParams.manual
								),
							});
						}

						// If order is not manual, continue to success page
					} else {
						// If user has accepted marketing cookies, send to Google Adwords Conversion
						trackGoogleAdsConversion({
							send_to: 'AW-832628793/SN9NCIj60bgZELnQg40D',
							value:
								(order?.tariffId?.includes('webspot')
									? 700
									: 1000) * 0.5234,
							currency: 'NOK',
							product_name: order?.tariffId,
							transaction_id: data.id,
						});

						// If user has stored elektro product order in session storage, navigate back to elektro (don't logout)
						if (!!storedElektroOrder?.product?.slug) {
							navigate(
								`/produkter/bestill?produkt=${storedElektroOrder?.product?.slug}`
							);
							// Logout and redirect to success page
						} else {
							logout({
								redirect: getLogoutUrl(
									stromPages.orderSuccess.url
								),
							});
						}
					}
				}
			})
			.catch(err => {
				storeBeforeLogout();
				console.error(err.message);
				logout({
					redirect: getLogoutUrl(
						stromPages.orderFailed.url,
						stromUrlParams.techError
					),
				});
			});
	}

	return (
		<Layout>
			<Header>Helt til slutt, se over at alt stemmer</Header>
			<ContentWrapper>
				<SubHeader>Kontaktinformasjon</SubHeader>
				<AnimatePresence initial={false} mode={'wait'}>
					{!editContactInfoOpen && (
						<motion.div
							variants={InfoBoxVariants}
							initial={'hidden'}
							animate={'visible'}
							key={'info-card'}
							exit={'hidden'}
							style={{
								transformOrigin: 'top left',
							}}>
							<InfoBox
								onEdit={() => {
									setEditContactInfoOpen(true);
									trackButtonClicked(
										'Rediger kontaktinformasjon'
									);
								}}
								ariaLabel="Rediger kontaktinformasjon"
								icon={<FontAwesomeIcon icon={faUser} />}
								title={`${user.fornavn}${
									user.mellomnavn ? ' ' + user.mellomnavn : ''
								} ${user.etternavn}`}
								values={[
									{
										key: 'tlf',
										value:
											'+ ' +
											formatPhoneToString(
												user.landskode
													? user.landskode +
															user.mobil
													: user.mobil
											),
									},
									{
										key: 'e-post',
										value: user.epost,
									},
									{
										key: 'fakturaadresse',
										value: addressObjectToString(
											order?.fakturaAdresse
										),
									},
								]}
							/>
						</motion.div>
					)}
					{editContactInfoOpen && (
						<motion.div
							variants={EditFormVariants}
							key={'edit-form'}
							initial="hidden"
							animate="visible"
							exit="hidden"
							style={{ transformOrigin: 'top left' }}>
							<InlineModal
								title="Endre kontaktinformasjon"
								onClose={() => {
									setEditFormError(defaultEditFormError);
									setEditContactInfoOpen(false);
								}}
								aria-expanded={editContactInfoOpen}>
								<Form
									onSubmit={e => {
										e.preventDefault();
										setEditFormError(defaultEditFormError);

										const values = {};
										values['epost'] =
											e.target.epost.value?.trim();

										values['telefonnummer'] =
											e.target.telefonnummer?.value
												?.trim()
												?.replace(/\D/g, '');

										values['cc'] =
											e.target[
												'telefonnummer-cc'
											]?.value?.trim();

										if (
											!validateContactInfoChange(values)
										) {
											return;
										}
										updateContactInfo(values);
										setEditContactInfoOpen(false);
									}}>
									<PhoneInputField
										name="telefonnummer"
										required={true}
										title="Telefonnummer"
										defaultValue={user.mobil}
										defaultValueCc={user.landskode}
										error={editFormError?.tel}
										onChange={() =>
											setEditFormError({
												...editFormError,
												tel: '',
											})
										}
										icon={
											<FontAwesomeIcon icon={faPhone} />
										}
									/>
									<InputField
										name="epost"
										type="email"
										required={true}
										title="E-postadresse"
										defaultValue={user.epost}
										error={editFormError?.epost}
										onChange={() =>
											setEditFormError({
												...editFormError,
												epost: '',
											})
										}
										icon={<FontAwesomeIcon icon={faAt} />}
									/>
									<SearchAddressField
										inputRef={addressInputRef}
										customTitle="Fakturaadresse"
										pickedAddress={pickedAddress}
										setPickedAddress={setPickedAddress}
										setAddressObject={setAddressObject}
										error={editFormError?.address}
										setError={error => {
											setEditFormError({
												...editFormError,
												address: error,
											});
										}}
										reqired={true}
										searchIsActive={searchIsActive}
										setSearchIsActive={setSearchIsActive}
									/>

									<GreenButton type="submit">
										Oppdater kontaktinformasjon
									</GreenButton>
								</Form>
							</InlineModal>
						</motion.div>
					)}
				</AnimatePresence>

				<SubHeader>Strømmen leveres til</SubHeader>
				<InfoBox
					icon={<FontAwesomeIcon icon={faHouseChimneyHeart} />}
					title={`${order?.maalerAdresse?.gate} ${order?.maalerAdresse?.husnummer}`}
					subtitle={`${order?.maalerAdresse?.postnummer} ${order?.maalerAdresse?.poststed}`}
					values={order.maalere.map(m => {
						return {
							key: 'Målernummer',
							value: m.maalernummer,
						};
					})}
				/>

				<ProductBoxMobileOnly />

				<Form onSubmit={e => onSubmit(e)}>
					<StyledCheckbox
						required={true}
						onChange={() => setFormError('')}
						name="kredittsjekk"
						label="Jeg godkjenner at NTE gjennomfører en kredittsjekk av meg."
					/>
					<StyledCheckbox
						required={true}
						onChange={() => setFormError('')}
						name="avtale"
						label={
							<span>
								Jeg aksepterer{' '}
								<AnchorLink
									href={
										isBedrift
											? '/filer/vilkaar-bedrift'
											: `${
													process.env
														.GATSBY_STROM_SERVER_URL ||
													'https://strombestilling-api.nte.no'
											  }/templates/terms-document?tariffId=${
													order.tariffId
											  }`
									}
									target="_blank"
									rel="noreferrer"
									title="Bestillingsvilkår for strømbestilling">
									bestillingsvilkårene for strømbestilling
								</AnchorLink>{' '}
								og NTE sin{' '}
								<AnchorLink
									href="/om-nte/personvernerklaering/"
									target="_blank"
									rel="noreferrer"
									title="Personvernerklæring">
									personvernerklæring.
								</AnchorLink>
							</span>
						}
					/>
					{hasAngrerett && !isBedrift && (
						<StyledCheckbox
							required={true}
							onChange={() => setFormError('')}
							name="angrerett"
							label={
								<span>
									Jeg er kjent med at jeg kan benytte meg av
									angreretten beskrevet i{' '}
									<AnchorLink
										href={
											isBedrift
												? '/filer/vilkaar-bedrift'
												: `${
														process.env
															.GATSBY_STROM_SERVER_URL ||
														'https://strombestilling-api.nte.no'
												  }/templates/terms-document?tariffId=${
														order.tariffId
												  }#Angrerett`
										}
										target="_blank"
										rel="noreferrer"
										title="Bestillingsvilkår for strømbestilling">
										bestillingsvilkårene for strømbestilling
									</AnchorLink>
									, ved å returnere{' '}
									<AnchorLink
										href="https://nte.no/angskjema"
										title="Angrerettsskjema"
										target="_blank"
										rel="noreferrer">
										angrerettsskjema
									</AnchorLink>{' '}
									til NTE innen {ANGRERETT_FRIST_DAGER} dager
									etter denne bestillingen.
									{isDateBeforeAngrerett(
										order.oppstartsdato
									) && (
										<>
											{' '}
											Fordi jeg ønsker oppstart før
											angrefristen vil kostnadene fram til
											jeg sier opp avtalen bli fakturert,
											selv om den sies opp innenfor
											angrefristen.
										</>
									)}
								</span>
							}
						/>
					)}

					{formError && <StromFormError error={formError} />}

					<Button
						type="submit"
						disabled={isBackendLoading || isAuthLoading}
						onClick={() => {
							if (editContactInfoOpen) {
								setFormError(
									'Boksen for å endre kontakinformasjon er åpen. Lukk den, eller lagre adressen for å fortsette.'
								);
								return;
							} else {
								setFormError(
									'Vi trenger samtykke til kredittsjekk og avtalevilkår for å gjennomføre bestillingen'
								);
							}
						}}>
						{isBackendLoading || isAuthLoading
							? 'Sender inn...'
							: 'Send bestilling'}
					</Button>
				</Form>
				<StromFormRequired />
				<Push style={{ height: '3rem' }} />
			</ContentWrapper>
		</Layout>
	);
}
