import BlankButton from 'components/forms/BlankButton';
import { AnchorLink } from 'components/Link';
import Popup from 'components/popup/Popup';
import { SubHeader } from 'components/strombestilling/components/StromStyles';
import { usePopup } from 'context/PopupProvider';
import AidonMaaler from 'images/strombestilling/Aidon_maaler.jpg';
import NuriMaaler from 'images/strombestilling/Nuri_maaler.png';
import React from 'react';
import styled from 'styled-components';

const ModalHeader = styled(SubHeader)`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.75rem;
	margin-bottom: 0.5rem;
`;

const MeterImageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
`;

const MeterImage = styled.img`
	width: 50%;
`;

export default function MeterInfoPopup({
	meterNumber = true,
	meterID = true,
	style,
}) {
	const { activePopup, setActivePopup } = usePopup();

	let title = 'Hvordan finner jeg målernummer eller målepunkt-ID?';

	if (meterNumber && !meterID) {
		title = 'Hvordan finner jeg målernummer?';
	} else if (!meterNumber && meterID) {
		title = 'Hvordan finner jeg målepunkt-ID?';
	}

	if (!meterNumber && !meterID) {
		return null;
	}

	return (
		<>
			<BlankButton
				aria-haspopup={true}
				aria-controls="power-meter-information"
				aria-expanded={activePopup === 'power-meter-information'}
				style={style}
				onClick={e => {
					e?.preventDefault();
					setActivePopup('power-meter-information');
				}}>
				{title}
			</BlankButton>
			<Popup id="power-meter-information" title={title} size="small">
				{meterNumber && (
					<div>
						<ModalHeader>
							Hvordan finner jeg målernummeret?
						</ModalHeader>
						I sikringsskapet ditt finner du en strømmåler, og på
						strømmåleren finner du målernummeret. Målernummeret
						inneholder kun siffer, men antallet siffer kan variere
						fra måler til måler.
						<br />
						Her er noen eksempler på hvor du kan finne nummeret.
						<br />
						<MeterImageWrapper>
							<MeterImage
								src={AidonMaaler}
								alt="Bilde av Aidon-måler med målernummer uthevet. Nummeret finnes under en QR-kode på øvre venstre side av måleren"
							/>
							<MeterImage
								src={NuriMaaler}
								alt="Bilde av Nuri-måler med målernummer uthevet. Nummeret finnes under en QR-kode omtrent midt på måleren"
							/>
						</MeterImageWrapper>
						<br />
					</div>
				)}

				{meterID && (
					<div>
						<ModalHeader>Hvor finner jeg målepunkt-ID?</ModalHeader>
						Alle strømmålere i Norge har et unikt
						identifikasjonsnummer som kalles målepunkt-ID.
						Målepunkt-ID består av 18 siffer, de ti første er alltid
						7070575000, og de resterende 8 er ditt unike nummer. Du
						finner ikke målepunkt-ID på strømmåleren, men på
						fakturaen fra strømleverandøren din eller nettselskapet
						ditt. Hvis du ikke har en faktura tilgjengelig, kan du
						ringe nettselskapet og be de om å oppgi målepunkt-ID.
						<br />
						<br />
						Hvis du har en aktiv strømavtale registrert på deg, kan
						du også finne både målernummer og målepunkt-ID ved å
						logge deg inn på{' '}
						<AnchorLink
							href="https://minside.elhub.no/"
							target="_blank"
							rel="noreferrer"
							title="Finn målernummer og målepunkt-ID ved å
							logge deg inn på Min Side hos Elhub">
							"Min side" hos Elhub
						</AnchorLink>
						.
					</div>
				)}
			</Popup>
		</>
	);
}
