import { faBoltLightning } from '@fortawesome/pro-solid-svg-icons/faBoltLightning';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link, { AnchorLink } from 'components/Link';
import InputField from 'components/strombestilling/components/InputField';
import MeterInfoPopup from 'components/strombestilling/components/MeterInfoPopup';
import {
	Button,
	ButtonSecondary,
	ButtonsWrapper,
	ContentWrapper,
	Form,
	Header,
	Layout,
	Paragraph,
	Push,
	RuleErrorWrapper,
	StyledListOfRuleErrors,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import useValidate from 'components/strombestilling/helpers/useValidate';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { StromFormInfo } from '../components/StromFormMsgs';

const NORWEGIAN_METER_POINT_ID_PREFIX = '7070575000';
const NORWEGIAN_METER_POINT_ID_PREFIX_TEST = '7075620000';

export default function SearchMeternumber() {
	const { navigatePage } = usePageNavigation();
	const { trackStepCompleted, trackButtonClicked } = useTracking();
	const { getValidatedMeters, orderErrors, isValidating } = useValidate();
	const { order, updateOrder, searchResult, storedElektroOrder } =
		useContext(StromContext);
	const { updateSaleStatus } = useSale();
	const {
		isLoading,
		getMeterInfoFromMeternumber,
		getMeterInfoFromMeterpoindId,
	} = useBackend();

	const [formError, setFormError] = useState('');
	const [isMeterFound, setIsMeterFound] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		updateSaleStatus(salgsStatuser.meterNotFound);
		// eslint-disable-next-line
	}, []);

	console.log(storedElektroOrder);

	function checkIsMeterNumber(number) {
		const isDevelopment = process.env.GATSBY_URL !== 'https://nte.no';
		if (
			number?.length === 18 &&
			(number.slice(0, 10) === NORWEGIAN_METER_POINT_ID_PREFIX ||
				(isDevelopment &&
					number.slice(0, 10) ===
						NORWEGIAN_METER_POINT_ID_PREFIX_TEST))
		) {
			return false;
		}
		return true;
	}

	return (
		<Layout>
			<Header>Søk etter målernummer eller målepunkt-ID</Header>
			<ContentWrapper>
				<Paragraph>
					Målernummeret finner du på selve strømmåleren. Målepunkt-ID
					finner du på forrige strømregning.
				</Paragraph>
				<MeterInfoPopup />
				<Push />
				<Form
					onSubmit={async e => {
						e.preventDefault();
						setFormError('');
						setIsMeterFound(false);

						const maalernummerInput = e.target.maalernummer?.value
							?.replace(' ', '')
							?.trim();

						if (!maalernummerInput) {
							setFormError(
								'Vi trenger målernummer eller målepunkt-ID for å søke'
							);
							return;
						}

						let meters;
						const isMeterNumber =
							checkIsMeterNumber(maalernummerInput);
						if (isMeterNumber) {
							// Search with meter number and postnummer
							meters = await getMeterInfoFromMeternumber(
								maalernummerInput,
								searchResult.maalerAdresse.postnummer
							);
						} else {
							// Search with meter point id
							meters = await getMeterInfoFromMeterpoindId(
								maalernummerInput
							);
						}

						if (!meters || meters?.error || meters?.length === 0) {
							setFormError(
								<span>
									Vi fant ingen treff på måleren. Prøv på nytt
									eller finn både målernummer og målepunkt-ID
									ved å logge deg inn på{' '}
									<AnchorLink
										href="https://minside.elhub.no/"
										target="_blank"
										rel="noreferrer"
										title="Finn målernummer og målepunkt-ID ved å
							logge deg inn på Min Side hos Elhub">
										"Min side" hos Elhub
									</AnchorLink>
									.
								</span>
							);

							return;
						} else {
							const meter = meters[0];

							const validationResult = await getValidatedMeters(
								meters
							);
							if (validationResult.showError) {
								setFormError(
									<RuleErrorWrapper>
										{orderErrors.ruleBroken.single}
										<StyledListOfRuleErrors
											single={
												validationResult?.infoArray
													?.length < 2
											}>
											{validationResult?.infoArray.map(
												(info, i) => (
													<li key={i}>
														{isMeterNumber
															? info
															: info
																	.replace(
																		meter.maalernummer,
																		meter.maalepunktId
																	)
																	.replace(
																		'Målernummer',
																		'Målepunkt-ID'
																	)}
													</li>
												)
											)}
										</StyledListOfRuleErrors>
									</RuleErrorWrapper>
								);
								return;
							}

							setIsMeterFound(true);

							updateOrder({
								maalere: [
									{
										maalernummer: meter.maalernummer,
										maalepunktId: meter.maalepunktId,
									},
								],
								maalerAdresse:
									meter.adresse || searchResult.maalerAdresse,
								isManual: false,
							});
							trackStepCompleted(steps[3]);
							updateSaleStatus(
								salgsStatuser.meterFoundAfterSearch
							);
							navigatePage(stromPages.addStartdate.url);
						}
					}}>
					<InputField
						name="maalernummer"
						title="Målernummer eller målepunkt-ID"
						type="number"
						inputRef={inputRef}
						icon={<FontAwesomeIcon icon={faBoltLightning} />}
						placeholder="000000000000"
						error={formError}
						onChange={() => {
							setFormError('');
						}}
					/>

					<ButtonsWrapper>
						<Button
							type="submit"
							disabled={isLoading || isValidating || formError}>
							{isMeterFound ? (
								<FontAwesomeIcon
									style={{ fontSize: '20px' }}
									icon={faCircleCheck}
								/>
							) : isLoading || isValidating ? (
								'Søker etter måleren...'
							) : (
								'Søk etter måleren'
							)}
						</Button>
						{order?.isElektroOrder ? (
							<>
								{storedElektroOrder?.product?.internalTitle && (
									<StromFormInfo
										info={
											<span>
												Bestilling av{' '}
												<b>
													{
														storedElektroOrder
															?.product
															?.internalTitle
													}
												</b>{' '}
												på avbetaling med{' '}
												{
													storedElektroOrder?.power
														?.product
												}{' '}
												kan ikke gjennomføres uten
												målernummer eller målepunkt-ID.
												Du kan{' '}
												<Link
													to={`/produkter/bestill?produkt=${storedElektroOrder?.product?.slug}`}>
													gå tilbake og bestille
													produktet med faktura som
													betalingsmetode
												</Link>
												.
											</span>
										}
									/>
								)}
								<ButtonSecondary
									type="button"
									onClick={() => {
										trackButtonClicked(
											'Tilbake til adressevelgeren'
										);
										navigatePage(stromPages.pickPath.url);
									}}>
									Tilbake til adressevelgeren
								</ButtonSecondary>
							</>
						) : (
							<ButtonSecondary
								type="button"
								onClick={() => {
									// This is now a manual order
									updateOrder({
										maalere: [],
										maalerAdresse:
											searchResult.maalerAdresse,
										isManual: true,
										manualMsg:
											'Bruker hadde ikke målernummer' +
											(inputRef.current?.value
												? `, og søkte nyligst på målernummer/målepunktId ${inputRef.current?.value}`
												: ''),
									});
									trackButtonClicked(
										'Jeg har ikke målernummer/målepunkt-ID'
									);
									trackStepCompleted(
										steps[3],
										'Ingen måler, manuell rute'
									);
									navigatePage(stromPages.addStartdate.url);
								}}>
								Jeg har ikke målernummer/målepunkt-ID
							</ButtonSecondary>
						)}
					</ButtonsWrapper>
				</Form>
			</ContentWrapper>
		</Layout>
	);
}
