import { AnchorLink } from 'components/Link';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import React, { useContext, useState } from 'react';

// In prioritised order
export const validationErrorNames = {
	// Before login
	GyldigTariffId: 'GyldigTariffId',
	TariffKreverVervekode: 'TariffKreverVervekode',
	GyldigVervekode: 'GyldigVervekode',
	TariffKreverSalgskode: 'TariffKreverSalgskode',
	SalgskodeEksisterer: 'SalgskodeEksisterer',
	SalgskodeUbrukt: 'SalgskodeUbrukt',
	TariffStotterVerving: 'TariffStotterVerving',
	GyldigSalgskanal: 'GyldigSalgskanal',
	// Måler
	TariffReglerFulgt: 'TariffReglerFulgt',
	MaalepunktIkkeBlokkertForBytte: 'MaalepunktIkkeBlokkertForBytte',
	GyldigMaalepunktId: 'GyldigMaalepunktId',
	HarMaalepunkterEllerManuell: 'HarMaalepunkterEllerManuell',
	// After login
	GyldigKundeId: 'GyldigKundeId',
	GyldigKundenummer: 'GyldigKundenummer',
	EksplisittKreditsjekkTillatt: 'EksplisittKreditsjekkTillatt',
	// Bedrift
	GyldigOrgnummer: 'GyldigOrgnummer',
	GyldigKundeOgOrgnummer: 'GyldigKundeOgOrgnummer',
};

export const contactSpan = (
	<span>
		vår kundeservice på{' '}
		<AnchorLink href="tel:+4774150200">+47 74 15 02 00</AnchorLink> eller
		via{' '}
		<AnchorLink href="/kundeservice/chat/" target="_blank">
			vår chat
		</AnchorLink>
	</span>
);

export default function useValidate() {
	const { order } = useContext(StromContext);
	const { validateTariffregler } = useBackend();
	const [isValidating, setIsValidating] = useState(false);

	async function getValidatedMeters(meters) {
		setIsValidating(true);
		const validation = await validateTariffregler(
			order.tariffId,
			meters.map(i => i.maalepunktId),
			order.vervekode,
			order.salgskode
		);
		let infoArray = [];
		const messages = {
			TariffReglerFulgt: 'har hatt avtale hos NTE de siste 12 månedene',
			MaalepunktIkkeBlokkertForBytte: 'er blokkert for bytte',
			GyldigMaalepunktId: 'er ikke et gyldig målepunkt',
		};

		if (validation?.errorList?.length) {
			if (meters?.length >= 1) {
				for (let i = 0; i < meters?.length; i++) {
					const errors = validation?.errorList.filter(
						error =>
							error.values.maalerpunktId ===
							meters[i].maalepunktId
					);
					if (!!errors) {
						errors.forEach(error => {
							meters[i].isInvalid = true;
							const alreadyInArray = infoArray.find(
								val => val.maaler === meters[i]?.maalernummer
							);
							if (alreadyInArray) {
								alreadyInArray.errors.push(
									messages[`${error.validationName}`] ||
										error.message.replace(/[0-9]{3,}/g, '')
								);
								return;
							}
							infoArray.push({
								maaler: meters[i].maalernummer,
								errors: [
									messages[`${error.validationName}`] ||
										error.message.replace(/[0-9]{3,}/g, ''),
								],
							});
						});
					}
				}
			}
		}
		const formattedArray = infoArray.map(value => {
			const errorsString = `${value.errors.map(
				(error, i, arr) =>
					`${error}${i === arr?.length - 1 ? '.' : ' og '}`
			)}`;

			return `Målernummer: ${value.maaler} ${errorsString.replace(
				',',
				''
			)}`;
		});
		setIsValidating(false);
		return {
			meters: meters,
			infoArray: formattedArray,
			showError: formattedArray?.length >= meters.length,
		};
	}

	const orderErrors = {
		ruleBroken: {
			single: (
				<span>
					{window?.location.pathname?.includes('adresse/')
						? 'Måleren på denne adressen oppfyller ikke '
						: 'Måleren du skrev inn oppfyller ikke '}{' '}
					vilkårene for{' '}
					{order?.tariffData?.navn || ' den valgte strømavtalen'}. Søk
					etter en annen
					{window?.location.pathname?.includes('adresse/')
						? ' adresse '
						: ' måler '}
					eller se{' '}
					<AnchorLink href="/strom" target="_blank">
						alle våre strømavtaler
					</AnchorLink>
					.
				</span>
			),
			multiple: (
				<span>
					{window?.location.pathname?.includes('adresse/')
						? 'Adressen du søkte på har målere som ikke oppfyller '
						: 'Målerene du skrev inn oppfyller ikke '}{' '}
					vilkårene for{' '}
					{order?.tariffData?.navn || ' den valgte strømavtalen'}. Søk
					etter en annen
					{window?.location.pathname?.includes('adresse/')
						? ' adresse '
						: ' måler '}
					eller se{' '}
					<AnchorLink href="/strom" target="_blank">
						alle våre strømavtaler
					</AnchorLink>
					.
				</span>
			),
		},
	};

	return {
		isValidating,
		getValidatedMeters,
		orderErrors,
	};
}
