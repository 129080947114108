import {
	Input as BaseInput,
	InputFieldWrapper as BaseInputFieldWrapper,
	Icon,
	preventButtonNumberChange,
	preventScrollNumberChange,
} from 'components/strombestilling/components/InputField';
import { StromFormError } from 'components/strombestilling/components/StromFormMsgs';
import { Label } from 'components/strombestilling/components/StromStyles';
import React, { useId } from 'react';
import { PatternFormat } from 'react-number-format';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${p => p.theme.colors.grey900};
`;

const InputFieldWrapper = styled(BaseInputFieldWrapper)`
	grid-template-columns: 2.5rem max-content minmax(max-content, 90%);
`;

const Input = styled(BaseInput)`
	padding: 0;
`;

const CCInput = styled(Input)`
	width: 30px;
`;

const PhoneInput = styled(Input)``;

const CountryCodeWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	margin-right: 0.1rem;
`;

export default function PhoneInputField({
	icon,
	error,
	title,
	placeholder,
	disabled,
	defaultValue,
	defaultValueCc,
	required,
	name,
	onChange,
	...rest
}) {
	const id = useId();

	return (
		<Wrapper>
			{title && <Label htmlFor={id}>{title}</Label>}
			<InputFieldWrapper isError={error} isDisabled={disabled}>
				{icon && <Icon isError={error}>{icon}</Icon>}
				<CountryCodeWrapper>
					<span>+</span>
					<CCInput
						type="number"
						onWheel={preventScrollNumberChange}
						onKeyDown={preventButtonNumberChange}
						key={`${name}-cc`}
						autoComplete="tel-country-code"
						id={`${id}-cc`}
						placeholder="47"
						defaultValue={defaultValueCc || 47}
						name={`${name}-cc`}
						required={required}
						aria-label="Landskode"
						onChange={onChange}
					/>
				</CountryCodeWrapper>
				<PatternFormat
					type="tel"
					key={name}
					name={name}
					id={id}
					placeholder={placeholder || null}
					disabled={disabled}
					defaultValue={defaultValue}
					required={required}
					onChange={onChange}
					aria-invalid={error}
					{...rest}
					customInput={PhoneInput}
					allowLeadingZeros={false}
					format="### ## ###"
					mask=" "
				/>
			</InputFieldWrapper>
			{error && <StromFormError error={error} />}
		</Wrapper>
	);
}
