import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnchorLink } from 'components/Link';
import InputField from 'components/strombestilling/components/InputField';
import {
	Button,
	Form,
	Header,
	Layout,
	Paragraph,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import usePageNavigation, {
	stromPages,
	stromPagesBaseUrl,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import { useAuth } from 'context/AuthProvider';
import React, { useContext, useEffect, useState } from 'react';

export default function AddOrgNumber() {
	const { logout } = useAuth();
	const { order, updateOrder, updateUser, storeBeforeLogout } =
		useContext(StromContext);
	const { navigatePage, getLogoutUrl } = usePageNavigation();
	const { trackStepCompleted } = useTracking();
	const { isLoading, getBedrift } = useBackend();

	const [isOrgFound, setIsOrgFound] = useState(false);
	const [formError, setFormError] = useState('');

	useEffect(() => {
		if (!order.isBedrift) {
			navigatePage(stromPages.pickPath.url, stromUrlParams.login);
		}
	}, [order.isBedrift, navigatePage]);

	useEffect(() => {
		// Log out on back navigation instead of going to keycloak login, same as in pickPath
		function handleBackNavigation(e) {
			if (
				window.location.pathname ===
					stromPagesBaseUrl + stromPages.loginSuccess.url + '/' ||
				window.location.pathname ===
					stromPagesBaseUrl + stromPages.loginSuccess.url
			) {
				storeBeforeLogout();
				logout({ redirect: getLogoutUrl(stromPages.login.url) });
			}
		}

		window.addEventListener('popstate', handleBackNavigation);
		return () => {
			window.removeEventListener('popstate', handleBackNavigation);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<Layout>
			<Header>Hva er organisasjons&shy;nummeret ditt?</Header>
			<Paragraph>
				Fortell oss hvilken bedrift du bestiller strøm for. Du kan søke
				opp organisasjonsnummeret i{' '}
				<AnchorLink
					href="https://www.brreg.no/"
					target="_blank"
					rel="noreferrer"
					title="Søk opp organisasjonsnummeret i Brønnøysundsregisteret">
					Brønnøysundsregisteret
				</AnchorLink>
				.
			</Paragraph>
			<Form
				onSubmit={async e => {
					e.preventDefault();
					setFormError('');
					setIsOrgFound(false);

					const orgnr = e.target.orgnr?.value
						?.replace(' ', '')
						?.trim();

					if (!orgnr) {
						setFormError(
							'Vi trenger organisasjonsnummer for å søke'
						);
						return;
					} else if (orgnr.length !== 9) {
						setFormError(
							'Et organisasjonsnummer er 9 siffer langt'
						);
						return;
					}

					const bedrift = await getBedrift(orgnr);

					if (!bedrift || bedrift.error) {
						setFormError(
							'Vi kunne dessverre ikke finne noen bedrift med dette organisasjonsnummeret. Er du sikker på at det er skrevet rett?'
						);
						return;
					}

					setIsOrgFound(true);
					updateUser({
						orgnr: orgnr,
					});
					updateOrder({
						bedriftData: {
							...bedrift.bedriftsdata,
							maalere: bedrift.maalepunkter?.length
								? bedrift.maalepunkter.map(m => {
										return {
											maalernummer: m.maalernummer,
											maalepunktId: m.maalepunktId,
										};
								  })
								: [],
						},
					});

					trackStepCompleted(steps[21]);
					navigatePage(stromPages.pickPath.url, stromUrlParams.login);
				}}>
				<InputField
					name="orgnr"
					title="Organisasjonsnummer"
					type="number"
					icon={<FontAwesomeIcon icon={faBuilding} />}
					placeholder="000000000"
					error={formError}
					onChange={() => {
						setFormError('');
					}}
				/>

				<Button type="submit" disabled={isLoading || formError}>
					{isOrgFound ? (
						<FontAwesomeIcon
							style={{ fontSize: '20px' }}
							icon={faCircleCheck}
						/>
					) : isLoading ? (
						'Søker etter bedriften...'
					) : (
						'Søk etter bedriften'
					)}
				</Button>
			</Form>
		</Layout>
	);
}
